import gql from 'graphql-tag';
import { client } from 'App';

const STATISTICS = gql`
  query {
    AdminStatistics {
      label
      value
    }
  }
`;

const statisticsProvider = {
  statistics: async () => {
    try {
      const res = await client.query({
        query: STATISTICS,
      });

      const {
        data: { AdminStatistics: statistics },
      } = res;

      return Promise.resolve(statistics);
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
};

export default statisticsProvider;
