import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  FunctionField,
  NumberInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  Edit,
  required,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  BooleanInput,
  Filter,
  SearchInput,
  Pagination,
  Button,
  Show,
  SimpleShowLayout,
  ShowButton,
  SelectField,
  BooleanField,
  ReferenceField,
} from 'react-admin';
import { NUMBER_OF_LEASED_UNITS, NUMBER_OF_UNITS, STATES } from 'utils/constants';
import CustomExportButton from "../../components/CustomExportButton";
import AddressField from "../../components/AddressField";

const Action = ({ total, className, basePath, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      <CustomExportButton
        jobName={'companyExportReport'}
        disabled={total === 0}
      />
    </TopToolbar>
  );
};

const CompanyFilters = (props) => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
    </Filter>
);

const CompanyPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const ModelList = props => (
  <List
    {...props}
    filters={<CompanyFilters />}
    actions={<Action/>}
    perPage={100}
    pagination={<CompanyPagination/>}
  >
    <Datagrid>
      <TextField source='id' />
      <TextField source='companyName' label={'Company Name'} />
      <TextField
        source='user.email'
        label={'Property Manager Email'}
      />
      <FunctionField
        label={'Created At'}
        source="createdAt"
        render={record => new Date(record.createdAt).toLocaleString()}
      />
      <EditButton label="Edit"/>
      <ShowButton label="View"/>
    </Datagrid>
  </List>
);

const ModelCreate = props => (
  <Create {...props} redirect={'list'}>
    <SimpleForm undoable={false}>
      <TextInput
        source={`companyName`}
        options={{ label: 'Company Name' }}
        validate={[required()]}
      />
      <SelectInput
        source={`numberOfUnits`}
        choices={NUMBER_OF_UNITS}
        validate={[required()]}
      />
      <SelectInput
        source={`totalLeasedUnits`}
        choices={NUMBER_OF_LEASED_UNITS}
        validate={[required()]}
      />
      <ReferenceInput
        label={`Property Manager`}
        source={'user.id'}
        reference={`AdminUser`}
        validate={[required()]}
      >
        <AutocompleteInput optionText={`email`} source={`user`} />
      </ReferenceInput>
      <TextInput
        source={`address.address1`}
        options={{ label: 'Address Line 1' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.address2`}
        options={{ label: 'Address Line 2' }}
      />
      <TextInput
        source={`address.city`}
        options={{ label: 'City' }}
        validate={[required()]}
      />
      <SelectInput
        source={`address.state`}
        choices={ STATES }
        options={{ label: 'State' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.zip`}
        options={{ label: 'Zip' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.country`}
        options={{ label: 'Country' }}
        validate={[required()]}
      />
      <BooleanInput
          source={`showNomadicPropertyQuestions`}
          validate={[required()]}
          defaultValue={ false }
        />
    </SimpleForm>
  </Create>
);

const ModelEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm undoable={false}>
        <NumberInput source={`id`} disabled />
        <TextInput
          source={`companyName`}
          options={{ label: 'Company Name' }}
          validate={[required()]}
        />
        <SelectInput
          source={`numberOfUnits`}
          choices={NUMBER_OF_UNITS}
          validate={[required()]}
        />
        <SelectInput
          source={`totalLeasedUnits`}
          choices={NUMBER_OF_LEASED_UNITS}
          validate={[required()]}
        />
        <ReferenceInput
          label={`Property Manager`}
          source={'user.id'}
          reference={`AdminUser`}
          validate={[required()]}
        >
          <AutocompleteInput optionText={`email`} source={`user`} />
        </ReferenceInput>
        <TextInput
          source={`address.address1`}
          options={{ label: 'Address Line 1' }}
          validate={[required()]}
        />
        <TextInput
          source={`address.address2`}
          options={{ label: 'Address Line 2' }}
        />
        <TextInput
          source={`address.city`}
          options={{ label: 'City' }}
          validate={[required()]}
        />
        <SelectInput
          source={`address.state`}
          choices={ STATES }
          options={{ label: 'State' }}
          validate={[required()]}
      />
        <TextInput
          source={`address.zip`}
          options={{ label: 'Zip' }}
          validate={[required()]}
        />
        <TextInput
          source={`address.country`}
          options={{ label: 'Country' }}
          validate={[required()]}
        />
        <BooleanInput
          source={`showNomadicPropertyQuestions`}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

const ShowAction = ({ basePath, data }) => {
  return (
      <TopToolbar>
        <Button label='Back to List' onClick={() => { window.location.href = '/#/AdminCompany'; }} />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
  );
};

const ModelShow = (props) => (
    <Show {...props} actions={<ShowAction/>} >
      <SimpleShowLayout>
        <TextField
            source={'companyName'}
            label={'First Name'}
        />
        <SelectField
            source={`numberOfUnits`}
            choices={NUMBER_OF_UNITS}
        />
        <SelectField
            source={`totalLeasedUnits`}
            choices={NUMBER_OF_LEASED_UNITS}
        />
        <ReferenceField
            label={`Property Manager`}
            source={'user.id'}
            reference={`AdminUser`}
        >
          <TextField source={'email'} />
        </ReferenceField>
        <ReferenceField
            label='Address'
            source='address.id'
            reference='AdminAddress'
            link={false}
        >
          <AddressField source='address'/>
        </ReferenceField>
        <BooleanField
            source={`showNomadicPropertyQuestions`}
        />
      </SimpleShowLayout>
    </Show>
);

export default {
  name: 'AdminCompany',
  options: {
    label: 'Company',
  },
  show: ModelShow,
  list: ModelList,
  create: ModelCreate,
  edit: ModelEdit,
};
