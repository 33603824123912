import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';
import { client } from 'App';

const IMPERSONATE = gql`
  mutation impersonate($email: Email!) {
    impersonate(email: $email) {
      jwt
    }
  }
`;

const impersonationProvider = {
  impersonate: async (username, setCookie) => {
    try {
      const res = await client.mutate({
        mutation: IMPERSONATE,
        variables: {
          email: username,
        },
      });

      const {
        data: { impersonate: jwt },
      } = res;
      const data = jwtDecode(jwt.jwt);

      setCookie('impersonation_auth_token', jwt.jwt, { domain: process.env.REACT_APP_MAIN_DOMAIN, path: '/', secure: true });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
};

export default impersonationProvider;
