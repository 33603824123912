import React from 'react';
import { Button } from 'react-admin';
import impersonationProvider from "../../Admin/impersonationProvider";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { useCookies } from 'react-cookie';

function ImpersonateButton({ record }) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => setLoading(false), []);
  const [cookies, setCookie] = useCookies(['rentsafe_user']);

  const handleClick = async () => {
      setLoading(true);
      const res = await impersonationProvider.impersonate(record.email, setCookie).then( () => {
          setLoading(false);
          window.open(process.env.REACT_APP_RENTER_UI_URL);
      });
  };

  return <Button label='Impersonate' onClick={handleClick} disabled={loading} startIcon={<PersonOutlineIcon />} />;
}

export default ImpersonateButton;
