import { Admin as RAdmin, Resource } from 'react-admin';
import React from 'react';
import authProvider from './authProvider';
import company from './components/Company';
import Dashboard from './components/Dashboard';
import user from './components/User';
import applicantData from 'Admin/components/ApplicantData';
import address from 'Admin/components/Address';
import application from 'Admin/components/Application';
import companyApplications from 'Admin/components/CompanyApplications'

function Admin({ provider }) {
  return (
    <RAdmin
      title={`Rent Safe Admin`}
      authProvider={authProvider}
      dataProvider={provider}
      dashboard={Dashboard}
    >
      <Resource {...user} />
      <Resource {...application} />
      <Resource {...applicantData} />
      <Resource {...company} />
      <Resource {...companyApplications} />
      <Resource {...address} />
    </RAdmin>
  );
}

export default Admin;
