import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    stats: {
        maxWidth: 300,
        margin: 25,
    },
});

function StatisticsCard({stat}) {
    const classes = useStyles();

    return (
    <Card className={classes.stats}>
        <CardContent>
            <Typography gutterBottom variant="h6" component="div">
                {stat['label']}
            </Typography>
            <Typography variant="body1">
                {stat['value']}
            </Typography>
        </CardContent>
    </Card>
    );
}

export default StatisticsCard;
