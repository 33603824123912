import React from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  SearchInput,
  Pagination,
  Show,
  TabbedShowLayout,
  Tab,
  ShowButton,
  Button,
  BooleanField,
} from 'react-admin';
import AddressField from 'components/AddressField';
import CustomExportButton from "../../components/CustomExportButton";

const Action = ({ total, className, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CustomExportButton
        jobName={'appDataExportReport'}
        disabled={total === 0}
      />
    </TopToolbar>
  );
};

const ApplicantDataFilters = (props) => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
    </Filter>
);

const ApplicantDataPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]} {...props} />;

const ModelList = props => (
  <List
    {...props}
    filters={<ApplicantDataFilters />}
    actions={<Action/>}
    perPage={100}
    pagination={<ApplicantDataPagination/>}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source={'user.firstName'}
        label={'First Name'}
      />
      <TextField
        source={'user.lastName'}
        label={'Last Name'}
      />
      <TextField source={'user.email'} label={'Email'}/>
      <TextField source={'user.phone'} label={'Phone'}/>
      <ReferenceField
        label='Property Address'
        source='address.id'
        reference='AdminAddress'
        link={false}
      >
        <AddressField source='address'/>
      </ReferenceField>
      <ReferenceField
        label='Application Type'
        source='application.id'
        reference='AdminApplication'
        link={false}
      >
        <TextField source={'type'} label={'Application Type'}/>
      </ReferenceField>
      <ReferenceField
          label='Company'
          source='company.id'
          reference='AdminCompany'
          link={false}
      >
        <TextField source={'companyName'} label={'Company'}/>
      </ReferenceField>
      <TextField
        source={'renterStatus'}
        label={'Report Status'}
      />
      <ShowButton label="View"/>
    </Datagrid>
  </List>
);

const ShowAction = () => {
  return (
      <TopToolbar>
        <Button label='Back to List' onClick={() => { window.location.href = '/#/AdminApplicantData'; }} />
      </TopToolbar>
  );
};

const ModelShow = (props) => (
    <Show {...props} actions={<ShowAction/>} >
      <TabbedShowLayout>
        <Tab label="User Info">
          <TextField source={'user.firstName'} label={'First Name'} />
          <TextField source={'user.lastName'} label={'Last Name'} />
          <TextField source={'user.email'} label={'Email'}/>
          <TextField source={'user.phone'} label={'Phone'}/>
        </Tab>
        <Tab label="Application Detail" >
          <ReferenceField
              label='Property Address'
              source='address.id'
              reference='AdminAddress'
              link={false}
          >
            <AddressField source='address'/>
          </ReferenceField>
          <ReferenceField
              label='Application Type'
              source='application.id'
              reference='AdminApplication'
              link={false}
          >
            <TextField source={'type'} label={'Application Type'}/>
          </ReferenceField>
          <ReferenceField
              label='Property Application Status'
              source='application.id'
              reference='AdminApplication'
              link={false}
          >
            <TextField source={'status'} label={'Application Status'}/>
          </ReferenceField>
          <ReferenceField
              label='Company'
              source='company.id'
              reference='AdminCompany'
              link={false}
          >
            <TextField source={'companyName'} label={'Company'}/>
          </ReferenceField>
          <TextField
              source={'renterStatus'}
              label={'Report Status'}
          />
          <hr/>
          <TextField source={'currentStep'} label={'Current Step'} />
          <TextField source={'status'} label={'Status'} />
          <BooleanField label="Intl Address" source={'isInternationalAddress' } />
          <TextField source={'internationalRentalHistory'} label={'International Rental History'} />
          <TextField source={'renterStatus'} label={'Report Status'} />
          <BooleanField label="Paid?" source={'isPaid'} />
          <ReferenceField
              label='Current Address'
              source='currentAddress.id'
              reference='AdminAddress'
              link={false}
          >
            <AddressField source='currentAddress'/>
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default {
  name: 'AdminApplicantData',
  options: {
    label: 'Applicant Data',
  },
  show: ModelShow,
  list: ModelList,
};
