import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  SearchInput,
  Pagination,
  DateInput,
} from 'react-admin';
import CustomExportButton from "../../components/CustomExportButton";

const Action = ({ total, className, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {/* the custom export below has a bug and needs to be fixed */}
      {/*<CustomExportButton*/}
      {/*  jobName={'appDataExportReport'}*/}
      {/*  disabled={total === 0}*/}
      {/*/>*/}
    </TopToolbar>
  );
};

const ModelFilters = (props) => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <DateInput source="dateFrom" label={'From'} alwaysOn />
      <DateInput source="dateTo" label={'To'} alwaysOn />
    </Filter>
);

const ModelPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]} {...props} />;

const ModelList = props => (
  <List
    {...props}
    filters={<ModelFilters />}
    actions={<Action/>}
    perPage={100}
    sort={{ field: 'applicationCount', order: 'DESC' }}
    pagination={<ModelPagination/>}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source={'id'} label={'Id'}/>
      <TextField source={'companyName'} label={'Company Name'}/>
      <TextField source={'applicationCount'} label={'Total Apps'}/>
    </Datagrid>
  </List>
);

export default {
  name: 'AdminCompanyApplications',
  options: {
    label: 'Company Applications',
  },
  list: ModelList,
};
