import React, { useEffect, useState } from 'react';
import statisticsProvider from "../statisticsProvider";
import StatisticsCard from "../../components/StatisticsCard";

const Dashboard = () => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        statisticsProvider.statistics().then(r => setStats(r));
    }, []);

    if (!stats) {
        return <p>Loading...</p>;
    }

    return (
        <StatisticsCard stat={stats[0]}/>
    );
}

export default Dashboard;
