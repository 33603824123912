export const NUMBER_OF_UNITS = [
  { id: '', name: '- Choose One -', isDefault: true },
  { id: '0-60', name: '0-50' },
  { id: '50-200', name: '50-200' },
  { id: '200-500', name: '200-500' },
  { id: '500-1000', name: '500-1000' },
  { id: '1000+', name: '1000+' },
  { id: 'N/A', name: 'N/A' },
];

export const NUMBER_OF_LEASED_UNITS = [
  { id: '', name: '- Choose One -', isDefault: true },
  { id: '0-50', name: '0-50' },
  { id: '50-200', name: '50-200' },
  { id: '200-500', name: '200-500' },
  { id: '1000+', name: '1000+' },
];

export const STATES = [
  { id: 'AL', name: 'Alabama', value: 'AL' },
  { id: 'AK', name: 'Alaska', value: 'AK' },
  { id: 'AZ', name: 'Arizona', value: 'AZ' },
  { id: 'AR', name: 'Arkansas', value: 'AR' },
  { id: 'CA', name: 'California', value: 'CA' },
  { id: 'CO', name: 'Colorado', value: 'CO' },
  { id: 'CT', name: 'Connecticut', value: 'CT' },
  { id: 'DE', name: 'Delaware', value: 'DE' },
  { id: 'DC', name: 'District of Columbia', value: 'DC' },
  { id: 'FL', name: 'Florida', value: 'FL' },
  { id: 'GA', name: 'Georgia', value: 'GA' },
  { id: 'HI', name: 'Hawaii', value: 'HI' },
  { id: 'ID', name: 'Idaho', value: 'ID' },
  { id: 'IL', name: 'Illinois', value: 'IL' },
  { id: 'IN', name: 'Indiana', value: 'IN' },
  { id: 'IA', name: 'Iowa', value: 'IA' },
  { id: 'KS', name: 'Kansas', value: 'KS' },
  { id: 'KY', name: 'Kentucky', value: 'KY' },
  { id: 'LA', name: 'Louisiana', value: 'LA' },
  { id: 'ME', name: 'Maine', value: 'ME' },
  { id: 'MD', name: 'Maryland', value: 'MD' },
  { id: 'MA', name: 'Massachusetts', value: 'MA' },
  { id: 'MI', name: 'Michigan', value: 'MI' },
  { id: 'MN', name: 'Minnesota', value: 'MN' },
  { id: 'MS', name: 'Mississippi', value: 'MS' },
  { id: 'MO', name: 'Missouri', value: 'MO' },
  { id: 'MT', name: 'Montana', value: 'MT' },
  { id: 'NE', name: 'Nebraska', value: 'NE' },
  { id: 'NV', name: 'Nevada', value: 'NV' },
  { id: 'NH', name: 'New Hampshire', value: 'NH' },
  { id: 'NJ', name: 'New Jersey', value: 'NJ' },
  { id: 'NM', name: 'New Mexico', value: 'NM' },
  { id: 'NY', name: 'New York', value: 'NY' },
  { id: 'NC', name: 'North Carolina', value: 'NC' },
  { id: 'ND', name: 'North Dakota', value: 'ND' },
  { id: 'OH', name: 'Ohio', value: 'OH' },
  { id: 'OK', name: 'Oklahoma', value: 'OK' },
  { id: 'OR', name: 'Oregon', value: 'OR' },
  { id: 'PA', name: 'Pennsylvania', value: 'PA' },
  { id: 'RI', name: 'Rhode Island', value: 'RI' },
  { id: 'SC', name: 'South Carolina', value: 'SC' },
  { id: 'SD', name: 'South Dakota', value: 'SD' },
  { id: 'TN', name: 'Tennessee', value: 'TN' },
  { id: 'TX', name: 'Texas', value: 'TX' },
  { id: 'UT', name: 'Utah', value: 'UT' },
  { id: 'VT', name: 'Vermont', value: 'VT' },
  { id: 'VA', name: 'Virginia', value: 'VA' },
  { id: 'WA', name: 'Washington', value: 'WA' },
  { id: 'WV', name: 'West Virginia', value: 'WV' },
  { id: 'WI', name: 'Wisconsin', value: 'WI' },
  { id: 'WY', name: 'Wyoming', value: 'WY' },
];

